<template>
  <div class="columns columns-override">
    <div class="column col-8 col-sm-12 column-override">
      <h1>{{ $t("board_services.intro_headline") }}</h1>
    </div>
    <div class="column col-7 col-sm-12 column-override" v-html="$t('board_services.intro')" />
    <div class="column col-5 col-sm-12 column-override">
      <img
        src="../../assets/images/service/services_board-services_header_illustration.png"
        class="intro__image"
      />
    </div>

    <div class="column col-12">
      <div class="list--boxed list--boxed-left-bottom">
        <h3 class="graphic-hl">{{ $t("board_services.support_headline") }}</h3>
        <ul class="list__item list--check">
          <li
            v-for="(text, idx) in $t('board_services.support_list')"
            :key="idx"
            class="list__item"
          >
            {{ text }}
          </li>
        </ul>
      </div>
    </div>

    <div class="column col-8 col-sm-12 column-override">
      <h2 class="h1">{{ $t("board_services.statement_1_headline") }}</h2>
    </div>
    <div
      class="column col-8 col-sm-12 column-override"
      v-html="$t('board_services.statement_1_text')"
    ></div>

    <div class="column col-8 col-sm-12 column-override">
      <h2 class="h1">{{ $t("board_services.statement_2_headline") }}</h2>
    </div>
    <div
      class="column col-8 col-sm-12 col-last column-override"
      v-html="$t('board_services.statement_2_text')"
    ></div>

    <div class="column column--full-width-bg">
      <div
        class="column col-6 col-sm-12 col-md-10 col-lg-9 col-xl-8 txt--center column-override"
      >
        <h4>{{ $t("board_services.link_headline") }}</h4>
        <router-link class="button button--arrow-left" to="/contact">{{
          $t("navigation.letstalk")
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BoardServices",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin-top: 3rem;
}

.intro__image {
  @media (min-width: $size-md) {
    transform: translateY(-6rem);
  }
}
.column--full-width-bg {
  @include column--full-width-bg($color-primary-50);
}

.column--margin-top {
  margin-top: 3rem;
}

.list--boxed {
  @include list--boxed;
  @include list--boxed-left-bottom;

  justify-content: flex-start;

  > .list__item {
    padding: 1rem 1.5rem 1.5rem;

    &:last-child {
      margin-bottom: 3.5rem;
    }
  }
}

.list--boxed-left-bottom {
  @include list--boxed-left-bottom;
}

.list--check {
  @include list--check;

  &.list__item {
    align-items: flex-start;
    flex-basis: 100%;
    text-align: left;
    display: grid;
  }

  .list__item {
    flex: 0 1 50%;
    margin-bottom: 0.5em;
  }
}

.intro {
  &__image {
    max-width: 100%;
    object-fit: cover;
  }
}

.columns-override {
  margin-left: unset; 
  margin-right: unset;
}
.column-override {
  padding-left: unset; 
  padding-right: unset;
}
</style>
